<template>
    <section>
        <div class="row mx-0 mt-3">
            <div class="col-8 bg-white br-t-8 py-3">
                <div class="row mx-0">
                    <div class="col-12 f-18 f-600 text-general">
                        Seleccione el tipo de cupón
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0 pl-4">
                    <div class="col-auto px-0 d-middle">
                        <div :class="cupon == 1 ? 'active-button' : 'inactive-button'" class="d-middle cr-pointer br-8 px-4 py-2 f-14" style="width:162px;height:32px;" @click="cupon = 1">
                            <i class="icon-ok-circled f-18" />
                            Cupón
                        </div>
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                Este cupón se aplicará siempre y cuando el {{ $config.cliente }} cumpla con las condiciones del mismo.
                            </div>
                            <i class="icon-info-circled-alt text-general f-20 ml-2" />
                        </el-tooltip>
                    </div>
                    <div class="col-auto px-0 d-middle ml-3">
                        <div :class="cupon == 2 ? 'active-button' : 'inactive-button'" class="d-middle cr-pointer br-8 px-4 py-2 f-14" style="width:162px;height:32px;" @click="cupon = 2">
                            <i class="icon-ok-circled f-18" />
                            Bono fúturo
                        </div>
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                El {{ $config.cliente }} podrá tener el beneficio de un bono siempre y cuando realice una compra inicial que cumpla con ciertas condiciones
                            </div>
                            <i class="icon-info-circled-alt text-general f-20 ml-2" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="row mx-0 px-3 mt-4">
                    <div class="col-auto d-middle">
                        <div class="d-middle cr-pointer br-10 py-2">
                            <input v-model="todosClientes" :value="1" type="radio" class="option-input radio black" />
                            Aplica a todos los {{ $config.cliente + 's' }}
                        </div>
                    </div>
                    <div class="col-auto d-middle">
                        <div class="d-middle cr-pointer br-10  py-2">
                            <input v-model="todosClientes" :value="2" type="radio" class="option-input radio black" />
                            Aplica a {{ $config.cliente + 's' }} especificos
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="cupon == 2" class="row mx-0 pt-2 mt-5">
            <div class="col-8 d-flex border-bottom mb-2">
                <div
                class="col pt-0 d-middle-center cr-pointer"
                :class="tab=='cupon1'? 'border-b-black text-general':''"
                @click="tab='cupon1'"
                >
                    Primer Cupón
                </div>
                <div
                class="col pt-0 d-middle-center cr-pointer disabled-div"
                :class="tab=='cupon2'? 'border-b-black text-general':''"
                @click="tab='cupon2'"
                >
                    Cupón Futuro
                </div>
            </div>
            <div class="col-12 px-0">
                <datos-basicos :crear="true" :tipo="cupon" :clientes="todosClientes" />
            </div>
        </div>
        <template v-if="cupon == 1">
            <datos-basicos :crear="true" :tipo="cupon" :clientes="todosClientes" />
        </template>
    </section>
</template>

<script>
export default {
    components: {
        datosBasicos:() => import('../componentes/datosBasicos'),
    },
    data(){
        return {
            tab:'cupon1',
            cupon: 1,
            todosClientes:1,
        }
    }
}
</script>
<style lang="scss" scoped>
.active-button{
    background-color: var(--color-general);
    color: #ffffff;
}
.inactive-button{
    border: 1px solid #DBDBDB;
    background-color: #FFFFFF;
    color: #000000;
}
</style>
